const alertData = [
    {
        id: 1,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Concluant',
    },
    {
        id: 2,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Non concluant',
    }
];

export { alertData };